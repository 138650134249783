<template>
  <List el="xsm" style="padding: 32px">
    <!-- <Text el="h2">{{ $route.params.id }}</Text> -->
    <List el="column-between" class="v-center">
      <Text el="h3" v-if="application"
        >{{ application.firstname }} {{ application.lastname }}</Text
      >
      <Text el="h3" v-else>Not Found...</Text>
      <Icon name="close" @click="close" />
    </List>
    <Text el="p" v-if="application != null">{{
      application.program.name
    }}</Text>
    <Accordion v-if="application != null">
      <List el="column-between xsm" style="align-items: center">
        <List el="column xsm" style="align-items: center">
          <!-- <Icon name="add" /> -->
          <Text el="h5">More details</Text>
        </List>
        <span class="material-icons"></span>
      </List>
      <List el="xsm">
        <Text el="p"
          >Submitted On: {{ formatDate(application.submittedOn) }}</Text
        >
        <Text el="p">Referral Code: {{ application.referralCode }}</Text>
        <Text el="p">ID: {{ application.id }}</Text>
        <Text el="p">Notes:</Text>
        <textarea type="text" v-model="application.notes" @click.stop></textarea>
        <Btn @click.stop="saveNotes">Save Notes</Btn>
        <!-- <div class="tags" v-if="application != null">
          <Text v-for="item in application.tags" :key="item">{{ item }}</Text>
        </div> -->
      </List>
    </Accordion>
    <hr el />
    <Text el="p" v-if="application != null"
      >Alphaleaf ID: {{ toLowerCase(application.firstname.substring(0, 1))
      }}{{ toLowerCase(application.lastname) }}</Text
    >
    <div v-if="application != null">
      <div
        el="toggle"
        :class="{ active: application.student }"
        @click="toggleStudent"
      >
        student
      </div>
    </div>
    <hr el />
    <Text el="h4">Contact Details</Text>
    <List el="xxsm" v-if="application != null">
      <Text el="p">First Name: {{ application.firstname }}</Text>
      <Text el="p">Last Name: {{ application.lastname }}</Text>
      <Text el="p">Middle Name: {{ application.middlename }}</Text>
      <Text el="p">Gender: {{ application.gender }}</Text>
      <Text el="p">Birthday: {{ application.birthday }}</Text>
      <Text el="p">Phone: {{ application.phone }}</Text>
      <Text el="p">Email: {{ application.email }}</Text>
      <Text el="p">Address: {{ formatAddress(application.address) }}</Text>
    </List>
    <Text el="h4">Request/Program Details</Text>
    <List el="xxsm" v-if="application != null">
      <Text el="p">Program: {{ application.program.name }}</Text>
      <Text el="p">Program Date: {{ application.program.date }}</Text>
      <Text el="p"
        >Submitted On: {{ formatDate(application.submittedOn) }}</Text
      >
      <Text el="p">Referral Code: {{ application.referralCode }}</Text>
    </List>
    <Text el="h4">Education</Text>
    <List el="xxsm" v-if="application != null">
      <Text el="p"
        >Has Coding Knowledge: {{ application.education.codingKnowledge }}</Text
      >
      <Text el="p"
        >English Proficiency:
        {{ application.education.englishProficiency }}</Text
      >
      <Text el="p">Education Level: {{ application.education.level }}</Text>
      <Text el="p"
        >Primary Language: {{ application.education.primaryLang }}</Text
      >
    </List>
    <Text el="h4">Background</Text>
    <List el="xxsm" v-if="application != null">
      <Text el="p"
        >Academic Probation:
        {{ application.background.academicProbation }}</Text
      >
      <Text el="p"
        >Citizenship Status: {{ application.background.citizenStatus }}</Text
      >
      <Text el="p"
        >Convicted Law Breaker:
        {{ application.background.convictedLawBreaker }}</Text
      >
      <Text el="p">Military: {{ application.background.military }}</Text>
      <Text el="p"
        >Pending Charges: {{ application.background.pendingCharges }}</Text
      >
      <Text el="p"
        >Professional Misconduct:
        {{ application.background.professionalMisconduct }}</Text
      >
      <Text el="p"
        >Can Legally Work in the US: {{ application.background.workInUS }}</Text
      >
    </List>
    <List
      el="column-stretch xsm"
      style="margin-top: 32px"
      v-if="application != null && $store.getters.isAdmin"
    >
      <Btn el="2ry" @click="deleteApplication(application.id)">Delete</Btn>
      <Btn
        @click="setApplicationStatus(application.id, 'publish')"
        v-if="application.status == 'draft'"
        >Publish</Btn
      >
      <Btn
        @click="setApplicationStatus(application.id, 'draft')"
        v-if="application.status == 'publish'"
        >Make Draft</Btn
      >
    </List>
    <!-- <List el="column-stretch xsm">
      <Btn el="2ry">Delete</Btn>
      <Btn>Add Files</Btn>
    </List> -->
  </List>
</template>
<script>
let startsWith = require("lodash/startsWith");
let toLower = require("lodash/toLower");
// let forEach = require("lodash/forEach");
import router from "../router";
import { db } from "@/firebase";
import { doc, getDoc, updateDoc, deleteDoc, setDoc } from "firebase/firestore";
// import { getStorage } from "firebase/storage";
import Icon from "@/components/btn/Icon.vue";

// const storage = getStorage();

export default {
  components: {
    Icon,
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      main: this.$store.state.main.layout.main,
      section: this.$store.state.main.layout.section,
      application: null,
    };
  },
  watch: {
    "$route.params.id": function () {
      this.getApplication();
    },
  },
  methods: {
    toLowerCase(text) {
      return toLower(text);
    },
    assignIcon(type) {
      let t = type.split("/");
      let icon = "description";
      switch (t[0]) {
        case "image":
          icon = "image";
          break;
        case "video":
          icon = "play_circle_filled";
          break;
      }
      return icon;
    },
    formatFilename(item) {
      if (startsWith(item.type, "video")) {
        let nameArr = item.name.split(".");
        nameArr.pop();
        return nameArr.join("");
      } else {
        return item.name;
      }
    },
    formatDate(date) {
      // const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const d = new Date(date);
      return d.toLocaleString();
    },
    formatAddress(address) {
      return `${address.street}, ${address.city}, ${address.state} ${address.zip}`;
    },
    async getApplication() {
      let routeParams = this.$route.params.id;
      if (routeParams != null) {
        const docRef = doc(db, "applicationForms", this.$route.params.id);
        const docSnap = await getDoc(docRef);
        let data = docSnap.data();
        data.id = docSnap.id;
        this.application = data;
      }
    },
    async toggleStudent() {
      var x = this;
      let student = !x.application.student;
      let docRef = doc(db, "applicationForms", this.$route.params.id);
      await setDoc(docRef, {
          student: student,
      }, { merge: true }).then(() => {
        console.log("Document successfully updated!");
        x.application.student = student;
      });
    },
    close() {
      this.closeSection();
      router.push("/dashboard/forms");
    },
    closeSection() {
      this.section.collapse = true;
    },
    async setApplicationStatus(id, status) {
      const docRef = doc(db, "applicationForms", id);
      // Set the "capital" field of the city 'DC'
      await updateDoc(docRef, {
        status: status,
      });
      this.closeSection();
      this.getApplication();
    },
    async saveNotes(){
      let x = this;
      const docRef = doc(db, "applicationForms", x.application.id);
      // Set the "capital" field of the city 'DC'
      await updateDoc(docRef, {
        notes: x.application.notes,
      });
    },
    async deleteApplication(id) {
      let x = this;
      var deletePrompt = prompt('To confirm please type "delete"');

      if (deletePrompt != null && deletePrompt == "delete") {
        // File deleted successfully
        deleteDoc(doc(db, "applicationForms", id));
        x.$store.commit("forms/deleteApplication", id);
        x.close();
      }
    },
  },
  mounted() {
    this.getApplication();
  },
};
</script>
<style lang="scss" scoped>
.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags > * {
  font-size: 12px;
  margin: 3px;
  padding: 3px 13px;
  /* border: 1px solid var(--color-7); */
  justify-self: flex-start;
  border-radius: 100px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
  user-select: none;
  color: var(--color-65);
  -webkit-text-fill-color: currentColor;
}
.tags > .active {
  color: var(--color-white);
  background-color: var(--color-cloud);
}
.file {
  padding: 4px 8px;
  border-radius: 5px;
  display: grid;
  // background-color: var(--color-sky-5);
  grid-auto-flow: column;
  align-items: center;
  text-decoration: none;
  grid-gap: var(--space-xxsm);
  transition: 0.2s;
  justify-content: flex-start;
  font-weight: bold;
}
.file:hover {
  background-color: var(--color-sky-5);
}
textarea{
  padding: 10px;
    background-color: #f1f1f1;
    border-radius: 3px;
    border: 0;
    font-size: 16px;
}
</style>
